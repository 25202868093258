<template>
  <div class="photoPoint">
    <LoadingPlaceholder v-if="preparing == true" />

    <SessionOverview
      v-show="preparing == false"
      v-if="photos != null && filter.startDate != null"
      :runs="photos"
      :loading="loading"
      :display-properties="['createDate', 'card']"
      :filter="filter"
      :installation-type="installationType"
      :installation-id="installationId"
      @loadSessions="loadSessions"
    />

    <SweetModal
      :id="modalId"
      ref="photoPointModal"
      title="Session"
      @closeModal="closeModal"
    >
      <sweet-modal-tab
        id="tab1"
        title="Photo"
      >
        <img
          v-if="altImageAvailable"
          id="img"
          :src="altImageUrl"
          class="img-fluid"
        >
        <ImagePlaceholderSvg v-else />
      </sweet-modal-tab>

      <sweet-modal-tab
        id="tab2"
        title="Details"
      >
        <table
          v-if="payload != null"
          class="defaultTable"
        >
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>Id</th>
              <td class="lineBreaks">
                {{ payload.id }}
              </td>
            </tr>
            <tr>
              <th>Card Number</th>
              <td class="lineBreaks">
                {{ cardNumber != null && cardNumber.length > 0 ? cardNumber : 'Not Avalible' }}
              </td>
            </tr>
            <tr>
              <th>Create date</th>
              <td v-tooltip="dateTime_fromNow(payload.created)">
                {{ dateTime_dateTime(payload.created) }}
              </td>
            </tr>
            <tr>
              <th>Image Url</th>
              <td>{{ payload.imageUrl }}</td>
            </tr>
            <tr>
              <th>Preview Url</th>
              <td>{{ payload.previewUrl }}</td>
            </tr>
            <tr>
              <th>Branded Image Url</th>
              <td>{{ payload.brandedImageUrl }}</td>
            </tr>
          </tbody>
        </table>
      </sweet-modal-tab>
      <sweet-modal-tab
        id="tab3"
        title="EXIF"
      >
        <pre v-if="exifData">{{ exifData }}</pre>
        <template v-else>
          {{ $t('noDataAvailable') }}
        </template>
      </sweet-modal-tab>
    </SweetModal>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

export default {
  name: 'PhotoPoint',
  components: {
    ImagePlaceholderSvg: () => import('@/components/Base/ImagePlaceholderSvg.vue'),
    SessionOverview: () => import('@/components/Media/SessionOverview.vue'),
    SweetModal,
    SweetModalTab
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    installationType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      useAlternativeFilter: true,
      loading: true,
      photos: null,
      offset: 0,
      limit: 100,
      cardNumber: null,
      altImageUrl: "",
      modalId: "photopointModal",
      filter: {
        dates: null,
        startDate: null,
        endDate: null,
        showIncomplete: true,
        cardNumber: null,
        limit: 100
      },
      preparing: true,
      payload: null,
      exifData: null
    }
  },
  computed: {
    areTherePhotos: function () {
      if (!this.photos) return false;
      if (this.photos.length > 0) return true;
      return false;
    },
    altImageAvailable: function () {
      if (this.altImageUrl && this.altImageUrl.length > 0) {
        return true;
      }
      return false;
    }
  },
  created () {
    let tmpDate = new Date(Date.now());
    this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 7, 0, 0, 0);
    this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
    this.filter.dates = [];
    this.filter.dates.push(this.filter.startDate, this.filter.endDate);

    this.loadSessions();
    this.$eventBus.$on(`openModal${this.modalId}`, this.openModal);
  },
  beforeDestroy () {
    this.$eventBus.$off(`openModal${this.modalId}`, this.openModal);
  },
  methods: {
    openModal (payload) {
      if (payload) {
        this.getExifData(payload);
        this.getCardNumber(payload);
        this.payload = payload;
        this.altImageUrl = payload.brandedImageUrl;
      }
      this.$refs.photoPointModal.open();
    },
    closeModal () {
      this.altImageUrl = '';
      this.exifData = null;
      this.$refs.photoPointModal.close();
    },
    loadSessions (payload) {
      if (payload == null || this.useAlternativeFilter == true) {
        payload = {
          limit: 100,
          dates: [this.useAlternativeFilter == true ? null : this.filter.startDate, this.useAlternativeFilter == true ? null : this.filter.endDate],
          cardNumber: this.useAlternativeFilter == true ? null : this.filter.cardNumber
        }
      }
      this.loading = true;
      this.axios.get(`/Media/GetPhotopoint/${ this.installationId }/${ payload.limit }/${ this.dateTime_isoString(payload.dates[1]) }/${ this.dateTime_isoString(payload.dates[0]) }/${ this.offset }?cardNumber=${ payload.cardNumber }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.photos = response.data;
        })
        .finally(() => {
          this.loading = false;
          window.setTimeout(() => {
            this.preparing = false;
          }, 500);
        });
    },
    getCardNumber (payload) {
      this.axios.get(`/Media/GetPhotopointCardNumber/${ payload.id }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.cardNumber = response.data;
        });
    },
    getExifData (payload) {
      if(payload.imageUrl) {
        var urlObj = {
          url: payload.imageUrl
        };

        this.axios.post(`/Media/GetExifData`, urlObj)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.exifData = JSON.stringify(JSON.parse(response.data),null,2);
        });
      }
    }
  }
}
</script>